<template>
  <v-container
    class="pa-9"
    fluid
  >
    <v-card class="elevation-1 rounded-0">

      <v-card-title>
        <h4 class="primary--text">{{ $t('users.manageUserAccess') }}</h4>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              :label="$t('users.type')"
              v-model="filters.roles"
              :items="roles"
              item-value="type"
              item-text="label"
              multiple
              chips
              small-chips
              deletable-chips
              single-line
            />
          </v-col>
          <v-col>
            <v-select
              :label="$t('users.organizers')"
              v-model="filters.organizers"
              :items="organizers"
              item-value="id"
              item-text="name"
              multiple
              chips
              small-chips
              deletable-chips
              single-line
            />
          </v-col>

          <v-col>
            <v-text-field
              :label="$t('forms.buttons.search')"
              v-model="filters.query"
            />
          </v-col>

          <v-col cols="1" class="text-right">
            <v-tooltip
              color="secondary"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="showCreateUsersModal"
                  v-bind="attrs"
                  v-on="on"
                  class="ml-auto"
                  icon
                  :loading="form.loading"
                >
                  <v-icon
                    size="20"
                    color="secondary"
                  >
                    fal fa-user-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('users.actions.create') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-data-table
        :headers="headers"
        :loading="form.loading"
        :items="users"
        :page.sync="form.page"
        :options.sync="options"
        :footer-props="{itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true}"
        :server-items-length="form.total"
        item-key="id"
        show-expand
        single-expand
        @item-expanded="handleUserExpanding"
      >

        <template v-slot:item.locked_at="{ item }">
          <v-icon
            :class="!item.attributes.locked_at ? 'green--text' : 'red--text'"
            size="10"
          >
            fas fa-circle
          </v-icon>
        </template>

        <template v-slot:item.role="{ item }">
          <span>{{ $t(`users.roles.${item.relationships.roles[0].role}`) }}</span>
        </template>

        <template v-slot:item.first_name="{ item }">
          <span>{{ item.attributes.full_name }}</span>
        </template>

        <template v-slot:item.email="{ item }">
          <span>{{ item.attributes.email }}</span>
        </template>

        <template v-slot:item.organization="{ item }">
          <span>
            {{ item.relationships.organizer ? item.relationships.organizer.name : '' }}
            {{ item.relationships.association ? item.relationships.association.name : '' }}
            {{ item.relationships.company ? item.relationships.company.attributes.company : '' }}
          </span>
        </template>

        <template v-slot:item.created_at="{ item }">
          <span>{{ item.attributes.created_at | dateByLocale }}</span>
        </template>

        <template v-slot:item.updated_at="{ item }">
          <span>{{ item.attributes.updated_at | dateTimeByLocale }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu
            open-on-click
            bottom
            left
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="secondary"
                icon
                v-on="on"
              >
                <v-icon>fal fa-ellipsis-v</v-icon>
              </v-btn>
            </template>

            <v-list>
              <template v-if="item.relationships.roles[0].role != USER_ROLES.EXHIBITOR">
                <v-list-item
                  v-if="!item.attributes.locked_at"
                  @click="showDeactivateUserModal(item)"
                >
                  <v-list-item-title>{{ $t('users.actions.deactivate') }}</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-else
                  @click="showActivateUserModal(item)"
                >
                  <v-list-item-title>{{ $t('users.actions.activate') }}</v-list-item-title>
                </v-list-item>
              </template>

              <v-list-item @click="showDeleteUserModal(item)">
                <v-list-item-title>{{ $t('users.actions.delete') }}</v-list-item-title>
              </v-list-item>
            </v-list>

          </v-menu>
        </template>

        <template v-slot:expanded-item="{item}">
          <td
            :colspan="headers.length"
            class="elevation-1 px-1 grey lighten-5"
          >
            <v-container fluid>
              <v-row>

                <v-col
                  cols="12"
                  class="px-7 pb-0"
                >
                  <h3>{{ item.attributes.name }}</h3>
                </v-col>

                <v-col
                  cols="6"
                  class="px-7"
                >
                  <v-text-field
                    :label="$t('forms.fields.userEmail')"
                    v-model="form.data.email"
                    :error-messages="form.errors.email"
                  />

                  <v-text-field
                    type="password"
                    :label="$t('forms.fields.password')"
                    v-model="form.data.password"
                    :error-messages="form.errors.password"
                    :hint="$t('forms.hints.password')"
                  />

                  <v-text-field
                    type="password"
                    :label="$t('forms.fields.passwordConfirmation')"
                    v-model="form.data.password_confirmation"
                    :error-messages="form.errors.password_Confirmation"
                    :hint="$t('forms.hints.password')"
                  />

                  <v-text-field
                    v-if="item.relationships.organization"
                    :label="$t('forms.fields.organization')"
                    disabled
                    :value="item.relationships.organization.name"
                  />

                  <v-text-field
                    v-if="item.relationships.association"
                    :label="$t('forms.fields.association')"
                    disabled
                    :value="item.relationships.association.name"
                  />

                  <v-select
                    v-model="form.data.gender"
                    :items="genders"
                    item-value="gender"
                    item-text="label"
                    :label="$t('forms.fields.msMr')"
                  />

                  <v-text-field
                    :label="$t('forms.fields.firstName')"
                    v-model="form.data.first_name"
                    :error-messages="form.errors.first_name"
                  />

                  <v-text-field
                    :label="$t('forms.fields.lastName')"
                    v-model="form.data.last_name"
                    :error-messages="form.errors.last_name"
                  />
                </v-col>

                <v-col
                  cols="6"
                  class="px-7"
                  v-if="userId"
                >

                  <v-checkbox
                    v-if="item.relationships.roles[0].role != USER_ROLES.EXHIBITOR"
                    v-model="form.data.active"
                    :label="$t('forms.fields.activeUser')"
                  />
                  <v-text-field
                    :label="$t('forms.fields.userType')"
                    :items="roles"
                    item-value="type"
                    item-text="label"
                    :value="$t(`users.roles.${item.relationships.roles[0].role}`)"
                    disabled
                  />

                  <v-autocomplete
                    v-if="item.relationships.roles[0].role == USER_ROLES.EXHIBITOR"
                    v-model="form.data.invited_company_id"
                    :error-messages="form.errors.general"
                    :items="companies"
                    :menu-props="{ maxHeight: '400' }"
                    item-value="attributes.company_id"
                    item-text="attributes.company"
                    :label="$t('forms.fields.invitedCompanyId')"
                    :hint="$t('forms.fields.invitedCompanyIdHint')"
                    :disabled="!!item.attributes.invited_company_id"
                    @update:search-input="loadCompanies"
                  ></v-autocomplete>

                  <v-col v-if="item.relationships.roles[0].role == USER_ROLES.EXHIBITOR" cols="12" class="px-0 py-1 mt-0">
                    <v-btn
                        class="secondary elevation-0"
                        :loading="form.loading"
                        :disabled="!!item.attributes.email_verified_at"
                        @click="handleConfirmEmailVerification(item)"
                    >
                      {{ $t('company.sendConfirmEmail') }}
                    </v-btn>
                  </v-col>

                  <v-col v-if="item.relationships.roles[0].role == USER_ROLES.EXHIBITOR" cols="12" class="px-0 py-1 mt-0">
                    <v-btn
                        class="secondary elevation-0"
                        :loading="form.loading"
                        :disabled="!!item.attributes.email_verified_at"
                        @click="handleResendEmailVerification(item)"
                    >
                      {{ $t('company.resendConfirmEmail') }}
                    </v-btn>
                  </v-col>

                  <v-col v-if="item.relationships.roles[0].role == USER_ROLES.EXHIBITOR" cols="12" class="px-0 py-1 mt-0">
                    <v-btn
                        :loading="form.loading"
                        @click="handleForgotPassword(item)"
                        class="secondary elevation-0"
                    >
                      {{ $t('company.sendPasswordReset') }}
                    </v-btn>
                  </v-col>


                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="secondary"
                    class="elevation-0"
                    @click="handleUserUpdate"
                    :loading="form.loading"
                  >
                    {{ $t('forms.buttons.save') }}
                  </v-btn>
                </v-col>

              </v-row>
            </v-container>
          </td>
        </template>

      </v-data-table>

    </v-card>

    <confirmation-modal
      ref="confirmationUserDeactivateModal"
      @confirm="handleUserDeactivate"
      :loading="form.loading"
      :async-hide="true"
    >
      {{ $t('common.generalConfirmation', { action: $t('users.actions.deactivate').toLowerCase() } )}}
    </confirmation-modal>

    <confirmation-modal
      ref="confirmationUserActivateModal"
      @confirm="handleUserActivate"
      :loading="form.loading"
      :async-hide="true"
    >
      {{ $t('common.generalConfirmation', { action: $t('users.actions.activate').toLowerCase() } )}}
    </confirmation-modal>

    <confirmation-modal
      ref="confirmationUserDeleteModal"
      @confirm="handleUserDelete"
      :loading="form.loading"
      :async-hide="true"
    >
      {{ $t('common.generalConfirmation', { action: $t('users.actions.delete').toLowerCase() } )}}
    </confirmation-modal>

    <create-user-modal
      ref="createUsersModal"
      @submit="handleUserCreate"
      :roles="roles"
      :companies="companies"
    />

  </v-container>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import { USER_ROLES } from '@/enums/userRole'
import { GENDERS } from '@/enums/genders'
import ConfirmationModal from '@/components/base/ConfirmationModal'
import CreateUserModal from '@/components/admin/Users/Modals/CreateUserModal'

export default {
  name: 'AdminUsers',

  components: {
    ConfirmationModal,
    CreateUserModal,
  },

  data() {
    return {
      companies: [],
      options: {},
      users: [],

      form: {
        loading: false,

        data: {
          email: '',
          password: '',
          password_confirmation: '',
          first_name: '',
          last_name: '',
          gender: '',
          active: '',
          invited_company_id: ''
        },

        errors: {},
        total: 0,
      },

      filters: {
        roles: [],
        organizers: [],
        query: '',
      },

      userId: null,
      USER_ROLES,
    }
  },

  validations() {
    return {
      form: {
        data: {
          email: {},
          password: {},
          passwordConfirmation: {},
          firstName: {},
          lastName: {},
          gender: {},
          active: {}
        },
      },
    }
  },

  computed: {
    ...mapGetters('organizer', [
      'organizers',
    ]),

    genders() {
      return Object.values(GENDERS).map((gender) => ({
        type: gender,
        label: this.$t(`common.genders.${gender}`),
      }))
    },

    roles() {
      return [
        {
          type: USER_ROLES.SUPERVISOR,
          label: this.$t('users.roles.supervisor'),
        },
        {
          type: USER_ROLES.ORGANIZER,
          label: this.$t('users.roles.organizer'),
        },
        {
          type: USER_ROLES.PROJECT_MANAGER,
          label: this.$t('users.roles.project_manager'),
        },
        {
          type: USER_ROLES.ADMINISTRATOR,
          label: this.$t('users.roles.administrator'),
        },
        {
          type: USER_ROLES.ASSOCIATION,
          label: this.$t('users.roles.association'),
        },
        {
          type: USER_ROLES.EXHIBITOR,
          label: this.$t('users.roles.exhibitor'),
        },
      ]
    },

    headers() {
      return [
        {
          text: '',
          value: 'data-table-expand',
        },
        {
          text: this.$t('users.userManagement.active'),
          value: 'locked_at',
          sortable: true,
        },
        {
          text: this.$t('forms.fields.type'),
          value: 'role',
          sortable: false,
        },
        {
          text: this.$t('forms.fields.name'),
          value: 'first_name',
          sortable: true,
        },
        {
          text: this.$t('forms.fields.organization') + '/' + this.$t('forms.fields.association') + '/' + this.$t('forms.fields.exhibitor'),
          value: 'organization',
          sortable: false,
        },
        {
          text: this.$t('forms.fields.email'),
          value: 'email',
          sortable: true,
        },
        {
          text: this.$t('forms.fields.created'),
          value: 'created_at',
          sortable: true,
        },
        {
          text: this.$t('forms.fields.lastUpdated'),
          value: 'updated_at',
          sortable: true,
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ]
    },
  },

  methods: {
    ...mapActions('organizer', [
      'fetchOrganizers',
    ]),

    ...mapActions('association', [
      'fetchAssociations',
    ]),

    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i] ? '-' + this.options.sortBy[i] : this.options.sortBy[i]
      }

      if (this.$route.query['user']) {
        let response = (await this.$axios.get('/api/users/' + this.$route.query['user'], {
          params: {
            'include': [
              'organizer',
              'association',
              'company',
              'language',
              'roles',
            ],
        }})).data

        this.users = [response.data]
        this.form.total = 1
      } else {
        let response = (await this.$axios.get('/api/users', {
          params: {
            'filter[role]': this.filters.roles.length ? this.filters.roles : this.roles.map((el) => el.type),
            'filter[organizer_id]': this.filters.organizers,
            'filter[search]': this.filters.query,
            'page[number]': this.options.page,
            'page[size]': this.options.itemsPerPage,
            'include': [
              'organizer',
              'association',
              'company',
              'language',
              'roles',
            ],
            'sort': sort,
          },
        })).data

        this.users = response.data
        this.form.total = response.meta.paginator.total
      }

      this.form.loading = false
    },

    loadCompanies: debounce(async function (search) {
      if (!this.user.attributes.invited_company_id && (!search || search.length < 3)) {
        return
      }

      const filter = this.user.attributes.invited_company_id ? { 'filter[company_id]': this.user.attributes.invited_company_id } : { 'filter[company]': search }

      let response = (await this.$axios.get('/api/companies', {
          params: {
            'languages': '*',
            'include': [
              'country',
              'company',
            ],
            ...filter,
            'page[number]': 1,
            'page[size]': 5,
          },
        })).data

        this.companies = this.companies.concat(response.data)
    }, 500),

    showCreateUsersModal() {
      this.$refs.createUsersModal.show()
    },

    showActivateUserModal(user) {
      this.userId = user.id

      this.$refs.confirmationUserActivateModal.show()
    },

    showDeactivateUserModal(user) {
      this.userId = user.id

      this.$refs.confirmationUserDeactivateModal.show()
    },

    showDeleteUserModal(user) {
      this.user = user
      this.userId = user.id

      this.$refs.confirmationUserDeleteModal.show()
    },

    handleUserExpanding({ item: user, value: isOpened }) {
      if (!isOpened) {
        this.form.data = {}
      } else {
        this.user = user
        this.userId = user.id

        this.form.data = { ...this.users.find((el) => el.id === user.id).attributes }
        this.form.data.active = this.form.data.locked_at === null && this.form.data.email_verified_at !== null
        this.form.data.invited_company_id = this.users.find((el) => el.id === user.id).attributes.invited_company_id
        this.form.errors.general = null
        this.loadCompanies()
      }
    },

    async handleUserDeactivate() {
      (await this.$axios.post(`/api/users/${this.userId}/lock`, this.form.data))

      this.$refs.confirmationUserDeactivateModal.hide()

      this.load()
    },

    async handleUserActivate() {
      (await this.$axios.post(`/api/users/${this.userId}/unlock`, this.form.data))

      this.$refs.confirmationUserActivateModal.hide()

      this.load()
    },

    async handleUserCreate() {
      this.load()
    },

    async handleUserDelete() {
      if (this.user.relationships.roles[0].role == USER_ROLES.EXHIBITOR) {
        await this.$axios.delete(`/api/companies/${this.user.attributes.company_id}/users/${this.userId}`)
      } else {
        (await this.$axios.delete(`/api/users/${this.userId}`, this.form.data))
      }

      this.user = null
      this.userId = null

      this.$refs.confirmationUserDeleteModal.hide()
      this.load()
    },

    async handleUserUpdate() {
      this.form.errors = {}
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (!this.user.attributes.invited_company_id && this.form.data.invited_company_id) {
        try {
          await this.$axios.post(`/api/companies/${this.form.data.invited_company_id}/users/invite`, {email: this.form.data.email})
        } catch (e) {
          if (e?.response?.status === 422) {
            this.form.errors.general = e?.response?.data?.errors.general

            return
          }
        }
      }

      // exhibitors does not have a locked handling
      if (this.user.relationships.roles[0].role == USER_ROLES.EXHIBITOR) {
        this.form.data.active = true
      }

      try {
        (await this.$axios.patch(`/api/users/${this.userId}`, this.form.data))
      } catch (e) {
        if (e?.response?.status === 422) {
          this.form.errors = e?.response?.data?.errors ?? {}
          return
        }
      }

      this.$snackbar(this.$t('common.successApiMessage'))
      this.load()
    },

    async handleResendEmailVerification(user) {
      this.form.loading = true

      await this.$axios.post(`/api/users/${user.id}/verify-notify`)

      this.form.loading = false
      this.$snackbar(this.$t('common.successApiMessage'))
    },

    async handleConfirmEmailVerification(user) {
      this.form.loading = true
      let response = await this.$axios.post(`/api/users/${user.id}/verify`)

      user.attributes.email_verified_at = response.data.data.attributes.email_verified_at

      this.form.loading = false
      this.$snackbar(this.$t('common.successApiMessage'))
    },

    async handleForgotPassword(user) {
      this.form.loading = true

      await this.$axios.post(`/api/forgot-password`, { email: user.attributes.email })

      this.form.loading = false
      this.$snackbar(this.$t('common.successApiMessage'))
    },

    loadDebounce: debounce(function() {
      this.load()
    }, 500),
  },

  async created() {
    this.load()
    this.fetchOrganizers()
    this.fetchAssociations()

    let response = (await this.$axios.get('/api/companies', {
      params: {
        'languages': '*',
        'include': [
          'country',
          'company',
        ],
        'page[number]': 1,
        'page[size]': 5,
      },
    })).data

    this.companies = this.companies.concat(response.data)
  },

  watch: {
    filters: {
      handler() {
        this.options.page = 1

        this.loadDebounce()
      },
      deep: true
    },

    'options'() {
      this.load()
    },
  },
}
</script>
